import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { genScheduleGroupPagePath, genStudiosEditPagePath } from '../../../format/path.format'
import { useStudio } from '../../../hooks/use-studios.hook'
import {
  genSchedulePageListIsLoading,
  genSchedulePageListRoomsWorkTime,
  genSchedulePageListStudioWorkTime,
  genSchedulePageListStudiosRoomsTags,
  genSchedulePageListTableRowList,
} from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { isDef } from '../../../types/lang.types'
import { AppModal } from '../../../types/modal.types'
import { useSchedulePageParams } from '../schedule-page-hooks/schedule-page-params.hook'
import {
  ScheduleGroupPageSection,
  ScheduleGroupPageUrlParams,
} from '../../schedule-group-page/schedule-group-page.types'
import { validateStrEnumValue } from '../../../utils/enum.utils'
import { TimetableItemType } from '../../../types/timetable.types'
import { schedulePageListActions } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'

export function useSchedulePageTable() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const { studioId, date } = useSchedulePageParams()
  const { studioOffset } = useStudio()

  const isLoading = useSelector(genSchedulePageListIsLoading)
  const schedule = useSelector(genSchedulePageListTableRowList)
  const studioWorkTime = useSelector(genSchedulePageListStudioWorkTime)
  const studiosRoomsTags = useSelector(genSchedulePageListStudiosRoomsTags)
  const studioRoomsWorkTime = useSelector(genSchedulePageListRoomsWorkTime)

  const onAddHandler = React.useCallback(
    (
      studioRoomId?: string,
      timeFrom?: string,
      timeTo?: string,
      isFrozenTime?: boolean,
      timetableType?: 'weekly' | 'daily',
      customDate?: string
    ): void => {
      if (isDef(studioOffset) && isDef(customDate ?? date)) {
        const roomId = studiosRoomsTags?.[0].id as string
        dispatch(
          modalActions.show({
            modal: AppModal.SCHEDULE_PAGE_MODAL_CREATE,
            props: {
              studioId,
              studioOffset,
              studioRoomId: studioRoomId ?? roomId,
              date: dayjs(customDate).format('YYYY-MM-DD') ?? date,
              timeFrom,
              timeTo,
              isFrozenTime,
              timetableType,
            },
          })
        )
      }
    },
    [date, dispatch, studioId, studioOffset]
  )

  const onViewHandler = React.useCallback(
    (scheduleId: string, activeKey: string): void => {
      const section = validateStrEnumValue<ScheduleGroupPageSection>(ScheduleGroupPageSection, activeKey)
      const params: ScheduleGroupPageUrlParams =
        isDef(section) && section !== ScheduleGroupPageSection.OVERVIEW
          ? { studioId, scheduleId, section }
          : { studioId, scheduleId }

      push({ pathname: genScheduleGroupPagePath(params), state: { date: date } })
    },
    [date, push, studioId]
  )

  const onMoveHandler = React.useCallback(
    (id: string, roomId: string, timeFrom: string, timeTo: string) => {
      dispatch(schedulePageListActions.updateExercise({ id, roomId, timeFrom, timeTo }))
    },
    [dispatch]
  )

  const onCancelHandler = React.useCallback(
    (id: string, eventType: TimetableItemType): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_PAGE_MODAL_CONFIRM,
          props: { id, eventType },
        })
      )
    },
    [dispatch]
  )

  const onEditStudioHandler = React.useCallback((): void => {
    push(genStudiosEditPagePath(studioId))
  }, [push, studioId])

  return {
    studioOffset,
    date,
    schedule,
    studioWorkTime,
    studioRoomsWorkTime,
    isLoading,
    studiosRoomsTags,
    onAddHandler,
    onViewHandler,
    onCancelHandler,
    onEditStudioHandler,
    onMoveHandler,
  }
}
