import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { Avatar, Button, message, Tooltip, Typography } from 'antd'
import { CopyOutlined, UserOutlined } from '@ant-design/icons'
import { clsx } from 'clsx'

import { ClientsInfoProps } from './clients-info.types'
import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '../../../constants/local-storage'
import { genTransactionsCreatePagePath } from '../../../format/path.format'
import './clients-info.styles.less'
import { formatPhoneNumber } from '../../../format/phone.format'

export const ClientsInfo: React.FC<ClientsInfoProps> = props => {
  const { push } = useHistory()

  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)

  const { className } = props
  const { name, photo, phone, id } = props

  return (
    <div className="clients-info-wrapper">
      <div className={clsx('clients-info', className)}>
        <Avatar
          className="clients-info__avatar"
          src={photo}
          icon={<UserOutlined />}
          alt={name}
          size={64}
          shape="square"
        />

        <Typography.Title level={4} className="clients-info__name">
          {name}
        </Typography.Title>

        <div className="clients-info__phone-wrapper">
          <Typography.Text className="clients-info__phone" type="secondary">
            {formatPhoneNumber(phone)}
          </Typography.Text>
          <Tooltip title="Скопировать номер телефона">
            <CopyToClipboard text={phone}>
              <Button
                onClick={() => {
                  message.success({
                    content: 'Номер успешно скопирован',
                    className: 'transactions-table__message',
                  })
                }}
                icon={<CopyOutlined />}
                size="middle"
              />
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
      <div>
        <Button
          disabled={!studioId}
          type="primary"
          onClick={() =>
            studioId &&
            push({
              pathname: genTransactionsCreatePagePath(studioId),
              state: { phone, clientId: id },
            })
          }
        >
          Быстрая продажа
        </Button>
      </div>
    </div>
  )
}
