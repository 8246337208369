import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import {
  mapExercisesToDirectionsOptions,
  mapExercisesToTrainersOptions,
  mapExercisesToRecordsOptions,
  mapExercisesToPaymentTypesOptions,
  mapTimetableItemsToTimetableDataItems,
} from '../../../../mapping/exercises.mapping'
import { mapStudiosRoomsToOptions, mapStudiosRoomsToTags } from '../../../../mapping/studios-rooms.mapping'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import { getStudioRoomsTimes, getStudioWorkTimes } from '../../../../mapping/studios.mapping'
import { BreaksApi } from '../../../../api/types/breaks.types'

export const genSchedulePageListExercises = (state: AppState): Nullable<ExercisesApi.Exercise[]> =>
  state.schedulePage.schedulePageList.exercises

const genBreaksInternal = (state: AppState): Nullable<BreaksApi.Break[]> => state.schedulePage.schedulePageList.breaks

const genStudioInternal = (state: AppState): Nullable<StudiosApi.Studio> => state.schedulePage.schedulePageList.studio

export const genStudiosRoomsInternal = (state: AppState): Nullable<StudiosRoomsApi.StudioRoom[]> =>
  state.schedulePage.schedulePageList.studiosRooms

export const genSchedulePageListIsLoading = (state: AppState): boolean => state.schedulePage.schedulePageList.isLoading
export const genSchedulePageListIsLoaded = (state: AppState): boolean => state.schedulePage.schedulePageList.isLoaded

export const genSchedulePageListIsCanceling = (state: AppState): boolean =>
  state.schedulePage.schedulePageList.isCanceling

export const genSchedulePageListTimetable = (state: AppState): 'weekly' | 'daily' =>
  state.schedulePage.schedulePageList.currentTimetable

export const genSchedulePageListTableRowList = createSelector(
  genSchedulePageListExercises,
  genBreaksInternal,
  mapTimetableItemsToTimetableDataItems
)

export const genSchedulePageListStudioWorkTime = createSelector(genStudioInternal, getStudioWorkTimes)

export const genSchedulePageListRoomsWorkTime = createSelector(genStudiosRoomsInternal, getStudioRoomsTimes)

export const genSchedulePageListStudiosRoomsTags = createSelector(genStudiosRoomsInternal, mapStudiosRoomsToTags)

export const getSchedulePageListDirectionOptions = createSelector(
  genSchedulePageListExercises,
  mapExercisesToDirectionsOptions
)

export const getSchedulePageListTrainerOptions = createSelector(
  genSchedulePageListExercises,
  mapExercisesToTrainersOptions
)

export const genSchedulePageListStudiosRoomsOptions = createSelector(genStudiosRoomsInternal, studiosRooms =>
  mapStudiosRoomsToOptions(studiosRooms)
)

export const genSchedulePageListStudiosRecordOptions = createSelector(
  genSchedulePageListExercises,
  mapExercisesToRecordsOptions
)

export const genSchedulePageListStudiosPaymentTypesOptions = createSelector(
  genSchedulePageListExercises,
  mapExercisesToPaymentTypesOptions
)
